<template>
  <div class="layout-header-section d-lg-flex d-block justify-content-between">
    <div class="header-label col-lg-5 col-12">List Users</div>
    <div class="
        col-lg-7 col-12
        text-end
        d-flex
        justify-content-end
        custom-flex-cloumn-mob
      ">
      <div class="input-group input-group-sm custom-form-group mb-0"
        v-bind:style="[searchnamebtnactive ? { width: '328px' } : { width: '275px' }]">
        <input type="text" v-model="searchfullname" class="form-control text-capitalize"
          placeholder="Search By First Or Last Name" aria-label="Search" aria-describedby="basic-addon1"
          autocomplete="off" maxlength="30" v-on:keyup.enter="searchFnameUser(searchfullname)" @keypress="isLetterWithSpace($event)" :disabled="hidesearchname"
          style="padding: 6px 15px;" />
        <button type="button" class="header-btn-outer btn btn-primary" :disabled="searchfullname.length == ''"
          @click="searchFnameUser(searchfullname)" :style="{ width: '50px' }">
          <span v-if="!searchnameloader"><i class="pi pi-search" aria-hidden="true"></i></span>
          <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="searchnameloader">
            <span class="visually-hidden">Loading...</span>
          </div>
        </button>
        <button type="button" class="header-btn-outer btn btn-primary ms-1" @click="searchNameUserStop()"
          :style="{ width: '50px' }" v-show="searchnamebtnactive"><i class="pi pi-times" aria-hidden="true"></i>
        </button>
      </div>
      <!-- <div class="input-group w-50 custom-search-input-outer">
        <span class="input-group-text" id="basic-addon1"><i class="pi pi-search" aria-hidden="true"></i></span>
        <input type="text" v-model="searchuser" class="form-control custom-form-search-group text-capitalize"
          placeholder="Search User Name" aria-label="Search" aria-describedby="basic-addon1"
          @input="searchUserList(searchuser)" v-on:keypress="isLetterWithSpace($event)" maxlength="35" />
      </div> -->
      <button type="button" @click="userfilterModalOpen()" class="header-filter-btn-outer btn btn-light" title="Filter"
        :disabled="dissableFilterButton == true">
        <span class="custom-filter-apply-alert" v-if="filterflag"></span>
        <i class="pi pi-filter-fill custom-text-primary" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="custom-ultima-datatable" style="height: calc(100vh - 141px)">
    <DataTable :value="clientUserList" :scrollable="true" columnResizeMode="fit" scrollHeight="flex" :paginator="true"
      :rows="30" :lazy="true" dataKey="ak1" :rowHover="true" :totalRecords="totalRecords" @page="changePage($event)"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      responsiveLayout="scroll" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      :loading="loading">
      <template v-if="!loading" #empty>No records found.</template>
      <template #loading><i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i></template>
      <Column field="Model" header="User" headerStyle="width: 27%" bodyStyle="width: 27%">
        <template #body="{ data }">
          <div>
            <div class="label-heading mb-2 text-capitalize">
              {{ data.ak5 ? data.ak5 : "N/A" }}
            </div>
            <div class="label-subheading text-capitalize">
              {{ data.ak111 ? data.ak111 : "N/A" }}</div>
          </div>
        </template>
      </Column>
      <Column field="mob" header="Mobile No." headerStyle="width: 15%" bodyStyle="width: 15%">
        <template #body="{ data }">
          <div>
            <div class="from-label-value" v-if="this.allowshowusermobile == 0">
              +91-{{ data.ak24 ? maskedNumber(data.ak24) : "N/A" }}
            </div>
            <div class="from-label-value" v-if="this.allowshowusermobile == 1">
              +91-{{ data.ak24 ? data.ak24 : "N/A" }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="teamlead" header="Team Lead" headerStyle="width: 25%" bodyStyle="width: 25%">
        <template #body="{ data }">
          <div class="text-capitalize">{{ data.ak103 ? data.ak103 : "Supreme User" }}
          </div>
        </template>
      </Column>
      <Column field="status" header="Status" headerStyle="width: 15%" bodyStyle="width: 15%">
        <template #body="{ data }">
          <div>
            <span v-if="data.ak82 == 1" class="status-active">Active</span>
            <span v-if="data.ak82 == 2" class="status-temporary-suspended">Temporary Suspended</span>
            <span v-if="data.ak82 == 3" class="status-permanently-suspended">Permanently Suspended</span>
          </div>
          <div v-if="this.alloweditvoterfk == 1" class="ms-3">
            <button v-if="data.ak114 == 1" type="button" title="Click To Unlock" class="btn custom-outline-lock-btn"
              @click="confirmUnlockModalOpen(data)" :disabled="unlockbtnloader">
              <span v-if="!unlockbtnloader"><i class="pi pi-lock lock-btn-color"></i></span>
              <div class="spinner-border text-bold custom-spinner-icon-loader-btn" role="status" v-if="unlockbtnloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </template>
      </Column>
      <Column field="Status" header="Action" class="justify-content-end" headerStyle="width: 18%" bodyStyle="width: 18%">
        <template #body="{ data }">
          <button type="button" title="View Details" class="btn custom-outline-view-btn me-2" @click="
            voterdetailModalOpen(data)
            ">
            <i class="pi pi-eye call-btn-color"></i> View
          </button>
          <button type="button" title="View Details" class="btn custom-outline-view-btn"
            @click="editClientUserInfoModalOpen(data)">
            <i class="pi pi-pencil call-btn-color"></i> Edit
          </button>
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="modal-mask" v-if="clientusermodals">
    <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Edit User</h5>
          <button type="button" class="btn-close" @click="closeClientUserModal()"></button>
        </div>
        <!-- Update User Stepper First start here -->
        <div class="modal-body modal-body-scroll-outer" v-if="!voterEditStepStatus">
          <div style="position: relative;
    min-height: 300px;">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label class="form-label">Mobile No.<span class="text-danger">*</span></label>
                            <div class="input-group custom-input-group">
                                <div class="col-lg-4 col-md-4 col-12">
                                    <Multiselect v-model="countrycode" :options="countryCodeList" :searchable="true"
                                        label="label" placeholder="Select" disabled
                                        class="multiselect-custom custom-title-multiselect" :canClear="false"
                                        :closeOnSelect="true" :object="true" noOptionsText="No Result"
                                        :canDeselect="false" />
                                </div>
                                <div class="col-lg-8 col-md-8 col-12">
                                    <input type="text" v-model="usermobilenumber" disabled class="form-control" 
                                        placeholder="Enter mobile No." maxlength="10" @paste="onPasteMobile" @keypress="onlyNumber"
                                        autocomplete="off" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                        <div class="custom-form-group">
                            <label for="formEmailInput" class="form-label">Email Id<span
                                    class="text-danger">*</span></label>
                            <input type="text" v-model="update.useremail" class="form-control" id="formEmailInput"
                                placeholder="Enter Email Id" autocomplete="off" maxlength="50" />
                            <div class="custom-error" v-if="v$.update.useremail.$error">
                              {{ v$.update.useremail.$errors[0].$message }}
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label for="formTitleInput" class="form-label">First Name<span
                            class="text-danger">*</span></label>
                        <div class="input-group custom-input-group">
                          <div class="col-lg-4 col-md-4 col-12">
                            <Multiselect v-model="update.title" :options="titleList" :searchable="false" label="label"
                              placeholder="Select" class="multiselect-custom custom-title-multiselect text-capitalize"
                              :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result"
                              :canDeselect="false" disabled />
                          </div>
                          <div class="col-lg-8 col-md-8 col-12">
                            <input type="text" v-model="update.firstname" class="form-control text-capitalize"
                              id="formFirstNameInput" placeholder="Enter First Name" autocomplete="off" maxlength="20"
                              @keypress="isLetterWithOutSpace($event)" disabled />
                          </div>
                        </div>
                        <div class="custom-error" v-if="v$.update.title.$error">
                          {{ v$.update.title.$errors[0].$message }}
                        </div>
                        <div class="custom-error" v-if="update.title != '' && v$.update.firstname.$error">
                          {{ v$.update.firstname.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label for="formMiddleNameInput" class="form-label">Middle Name</label>
                        <input type="text" v-model="update.middlename" class="form-control text-capitalize"
                          id="formMiddleNameInput" placeholder="Enter Middle Name" autocomplete="off" maxlength="20"
                          @keypress="isLetterWithOutSpace($event)" :disabled="update.middlename != ''" />
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label for="formLastNameInput" class="form-label">Last Name<span
                            class="text-danger">*</span></label>
                        <input type="text" v-model="update.lastname" class="form-control text-capitalize"
                          id="formLastNameInput" placeholder="Enter Last Name" autocomplete="off" maxlength="20"
                          @keypress="isLetterWithOutSpace($event)" disabled />
                        <div class="custom-error" v-if="v$.update.lastname.$error">
                          {{ v$.update.lastname.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Party Related Designation<span class="text-danger">*</span></label>
                        <Multiselect v-model="update.userdesignation" :disabled="edituserloader" :options="userdesignationList" :searchable="true"
                          label="label" placeholder="Select" class="multiselect-custom" :canClear="true"
                          :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        <div class="custom-error" v-if="v$.update.userdesignation.$error">
                          {{ v$.update.userdesignation.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 col-md-4 col-12" v-if="teamleadList != null && superteamlead == 0">
                      <div class="custom-form-group" >
                        <label class="form-label">Party Related Team Lead<span v-if="teamleadList != null"
                            class="text-danger">*</span></label>
                        <Multiselect v-model="update.teamleadname" :disabled="edituserloader" :options="teamleadList" :searchable="true"
                          label="label" placeholder="Select" class="multiselect-custom text-capitalize" :canClear="true"
                          :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        <div class="custom-error" v-if="v$.update.teamleadname.$error">
                          {{ v$.update.teamleadname.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-lg-4 col-md-4 col-12" v-if="teamleadList == null || clientUserList.length != 1">
                      <div class="custom-form-group">
                        <div class="form-check form-check-inline custom-inline-checkbox">
                          <input class="form-check-input" disabled v-model="update.superuser" type="checkbox"
                            id="whatsappimport" value="1" checked>
                          <label class="form-check-label" for="whatsappimport">Set As a Supreme User</label>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-lg-4 col-md-4 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">User Status<span class="text-danger">*</span></label>
                        <Multiselect v-model="update.userstatusflag" :disabled="edituserloader" :options="update.activeStatusList"
                          :searchable="false" label="label" placeholder="Select" class="multiselect-custom"
                          :canClear="false" :closeOnSelect="true" :object="true" noOptionsText="No Result" />
                        <div class="custom-error" v-if="v$.update.userstatusflag.$error">
                          {{ v$.update.userstatusflag.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6 col-md-6 col-12">
                      <div class="custom-form-group">
                        <label class="form-label">Permission Type<span class="text-danger">*</span></label>
                        <div class="custom-group-radio-box-btn">
                          <input type="radio" class="btn-check" :disabled="edituserloader" v-model="update.roletype" name="role-option" id="rolewise"
                            autocomplete="off" value="1" />
                          <label class="btn custom-radio-box-btn text-capitalize" for="rolewise">Set Wise</label>
                          <input type="radio" class="btn-check" :disabled="edituserloader" v-model="update.roletype" name="role-option"
                            id="manualrole" autocomplete="off" value="2" />
                          <label class="btn text-capitalize" for="manualrole">Manual Permissions</label>
                          <div class="custom-error" v-if="v$.update.roletype.$error">
                            {{ v$.update.roletype.$errors[0].$message }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-6 col-12" v-if="update.roletype == 1">
                      <div class="custom-form-group">
                        <label class="form-label">Permission Set<span class="text-danger">*</span></label>

                        <Multiselect v-model="update.userrole" :disabled="edituserloader" :options="userrole_list" :searchable="false" label="label"
                          placeholder="Select Permission Set" class="multiselect-custom text-capitalize" :canClear="true"
                          :closeOnSelect="true" />
                        <div class="custom-error" v-if="v$.update.userrole.$error && update.roletype == 1">
                          {{ v$.update.userrole.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="update.roletype == 2">
                    <div class="col-12">
                      <div class="custom-form-group">
                        <label for="formModulesInput" class="form-label">Select Modules<span
                            class="text-danger">*</span></label>
                        <div class="flex flex-wrap gap-2 mb-2">
                          <button type="button" class="btn custom-view-detail-btn me-2" @click="expandAll()"><i
                              class="pi pi-plus me-2" style="font-size: 0.7rem"></i>Expand All</button>
                          <button type="button" class="btn custom-view-detail-btn" @click="collapseAll"><i
                              class="pi pi-minus me-2" style="font-size: 0.7rem"></i>Collapse All</button>
                        </div>
                        <Tree :value="nodes" :disabled="edituserloader" selectionMode="checkbox" v-model:selectionKeys="update.selectedTreeValue"
                          :expandedKeys="expandedKeys" class="custom-users-role">
                        </Tree>
                        <div class="custom-error" v-if="v$.update.selectedTreeValue.$error && update.roletype == 2">
                          {{ v$.update.selectedTreeValue.$errors[0].$message }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="!voterEditStepStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateClientUserDetail()"
              :disabled="edituserloader">
              <span v-if="!edituserloader">Next</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="edituserloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Update User Stepper First End here -->
        <!-- Update User Stepper Second start here -->
        <div class="modal-body modal-body-scroll-outer" v-if="voterEditStepStatus">
          <div style="position: relative;
    min-height: 300px;">
            <div v-if="!showloadervotermodal">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to use Mobile App : </label>
                        <InputSwitch v-model="allow.usermobapppermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to make Outgoing Calls to voters :
                        </label>
                        <InputSwitch v-model="allow.displaymakeoutgoingcallvoterpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display voter's Mobile Number :
                        </label>
                        <InputSwitch v-model="allow.usermobpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display voter's Email ID :
                        </label>
                        <InputSwitch v-model="allow.useremailpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display Aadhaar No. : </label>
                        <InputSwitch v-model="allow.useraadharpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display user's Mobile Number :
                        </label>
                        <InputSwitch v-model="allow.displayusermobilenumberpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display user's Email ID :
                        </label>
                        <InputSwitch v-model="allow.displayuseremailidpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display Karyakarta's Mobile Number
                            : </label>
                        <InputSwitch v-model="allow.displaykaryakartamobilenumberpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to display Karyakarta's Email ID :
                        </label>
                        <InputSwitch v-model="allow.displaykaryakartaemailidpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to add a Voter & Voter's Family :
                        </label>
                        <InputSwitch v-model="allow.displayaddvoterfamilypermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to add Voter manually :
                        </label>
                        <InputSwitch v-model="allow.useraddvotermanually" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div
                        class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to edit Voter Details manually :
                        </label>
                        <InputSwitch v-model="allow.usereditpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to add Voter's Professional Information :
                        </label>
                        <InputSwitch v-model="allow.displayaddvoterprofessionalinfopermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to modify Voter's Professional Information :
                        </label>
                        <InputSwitch v-model="allow.displaymodifyvoterprofessionalinfopermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to manage or modify Family Relations of Voters :
                        </label>
                        <InputSwitch v-model="allow.displaymanagemodifyfamilyrelationpermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="d-flex align-items-center justify-content-between divider-custom-list customer-label-group">
                        <label class="from-label-value me-3">Allow to remove a Member from Voter's Family Member Group :
                        </label>
                        <InputSwitch v-model="allow.displayremovememberfromfamilygrouppermission" />
                    </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="customer-label-group divider-custom-list customer-label-group">
                      <div class="d-flex align-items-center justify-content-between">
                          <label class="from-label-value me-3">Allow to Voter Tag Addition Approver : </label>
                          <InputSwitch v-model="allow.displayvotertagaddpermission" />
                      </div>
                      <div class="d-flex align-items-center justify-content-end" v-if="this.allow.displayvotertagaddpermission == true">
                          <div class="custom-form-group mb-0 d-flex">
                              <div class="form-check custom-chat-radio-form form-check-inline">
                                  <input type="radio" class="form-check-input" v-model="allow.displayaddtagresponsepermission" name="none-option" id="directresponse"
                                      autocomplete="off" value="0" />
                                  <label class="form-check-label text-capitalize" for="directresponse">Directly</label>
                              </div>
                              <div class="form-check custom-chat-radio-form form-check-inline">
                                  <input type="radio" class="form-check-input" v-model="allow.displayaddtagresponsepermission" name="with-option" id="withapproval"
                                      autocomplete="off" value="1" />
                                  <label class="form-check-label text-capitalize" for="withapproval">With Approval</label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-lg-12 col-md-12 col-12">
                  <div class="customer-label-group divider-custom-list customer-label-group">
                      <div class="d-flex align-items-center justify-content-between">
                          <label class="from-label-value me-3">Allow to Voter Tag Removal Approver : </label>
                          <InputSwitch v-model="allow.displayvotertagremovalpermission" />
                      </div>
                      <div class="d-flex align-items-center justify-content-end" v-if="this.allow.displayvotertagremovalpermission == true">
                          <div class="custom-form-group mb-0 d-flex">
                              <div class="form-check custom-chat-radio-form form-check-inline">
                                  <input type="radio" class="form-check-input" v-model="allow.displayremovaltagresponsepermission" name="none-removeoption" id="removedirectresponse"
                                      autocomplete="off" value="0" />
                                  <label class="form-check-label text-capitalize" for="removedirectresponse">Directly</label>
                              </div>
                              <div class="form-check custom-chat-radio-form form-check-inline">
                                  <input type="radio" class="form-check-input" v-model="allow.displayremovaltagresponsepermission" name="with-removeoption" id="removewithapproval"
                                      autocomplete="off" value="1" />
                                  <label class="form-check-label text-capitalize" for="removewithapproval">With Approval</label>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
                <div class="col-lg-12 col-md-12 col-12">
                    <div class="customer-label-group">
                        <div class="customer-label-group">
                            <div class="d-flex align-items-center justify-content-between">
                                <label class="from-label-value me-3">Allow to Delete Voter : </label>
                                <InputSwitch @change="CheckAllowDeleteVoterAccess(allow.displaydeletevoterpermission)" v-model="allow.displaydeletevoterpermission" />
                            </div>
                            <small class="text-danger" v-if="this.deletevotermessage">{{this.deletevotermessage}}</small>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div v-if="showloadervotermodal">
              <div class="custom-modal-spinner-loader">
                <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" v-if="voterEditStepStatus">
          <div class="col-12 text-end">
            <button type="button" class="btn modal-bulk-next-btn" @click="updateClientUserPermission()"
              :disabled="editpermissionloader">
              <span v-if="!editpermissionloader">Submit</span>
              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="editpermissionloader">
                <span class="visually-hidden">Loading...</span>
              </div>
            </button>
          </div>
        </div>
        <!-- Update User Stepper Second end here -->
      </div>
    </div>
  </div>

  <!-- filter start here -->
  <div class="modal-mask" v-if="clientuserfiltermodals">
    <div class="
        modal-dialog modal-xs modal-dialog-scrollable modal-fullscreen
        ms-auto
        custom-modal-outer
      ">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Filter By</h5>
          <button type="button" class="btn-close" @click="userListfilterModalClose()"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="row">
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Mobile No.</label>
                    <input type="text" v-model="ak24" class="form-control" id="formMobileNoInput"
                      placeholder="Enter mobile Number" maxlength="10" @keypress="onlyNumberMobile" autocomplete="off" />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">Team Lead</label>
                    <Multiselect v-model="ak101" :options="teamleadList" :searchable="true" label="label"
                      placeholder="Select User Role" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
                <div class="col-lg-12 col-md-12 col-12">
                  <div class="custom-form-group">
                    <label class="form-label">User Status</label>
                    <Multiselect v-model="ak82" :options="userStatusList" :searchable="false" label="label"
                      placeholder="Select Status" class="multiselect-custom text-capitalize" :canClear="true"
                      :closeOnSelect="true" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 m-0">
            <div class="d-flex justify-content-between">
              <button class="btn filter-apply-btn" @click="filterListUser(ak24, ak101, ak82)">
                Apply Filter
              </button>
              <button class="btn btn-link filter-reset-btn" @click="resetUser">
                Reset All
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- filter end here -->
  <!-- confirmation modal start here -->
  <div class="modal-mask" v-if="confirm_unlock_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
              <div class="d-none">{{ this.ak1 }}</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showconfirmloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmUnlockModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="changelockstatus(this.ak1)"
              :disabled="showconfirmloaderbtn">
              <span v-if="!showconfirmloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showconfirmloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation modal end here -->
  <!-- next action modal start here -->
  <div class="modal-mask" v-if="viewvoterdetailstatus">
    <div class="modal-dialog modal-lg modal-dialog-centered custom-modal-outer">
      <div class="modal-content">
        <div class="modal-header custom-custmer-header-border">
          <h5 class="modal-title" id="staticBackdropLabel">User Details</h5>
          <button type="button" class="btn-close" @click="voterDetailModalClose()"></button>
        </div>
        <ul class="nav nav-tabs custom-customer-modal-tab" id="myTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button class="nav-link active" id="voter-idcard-tab" data-bs-toggle="tab" data-bs-target="#votercarddetails"
              type="button" role="tab" aria-controls="appointment-history" aria-selected="true"
              @click="getvoterdetails()">
              Voter Id
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-aadharcard-tab" data-bs-toggle="tab" data-bs-target="#voteraadhardetails"
              type="button" role="tab" aria-controls="vehicle-details" aria-selected="false" @click="getaadhardetails()">
              Aadhar
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-pancard-tab" data-bs-toggle="tab" data-bs-target="#voterpandetails"
              type="button" role="tab" aria-controls="customer-details" aria-selected="false" @click="getpandetails()">
              PAN
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-driving-tab" data-bs-toggle="tab" data-bs-target="#voterdrivingdetails"
              type="button" role="tab" aria-controls="contact-history" aria-selected="false" @click="getlicensedetails">
              Driving Licence
            </button>
          </li>
          <!-- <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-schemes-tab" data-bs-toggle="tab"
              data-bs-target="#voterschemesdetails" type="button" role="tab" aria-controls="customer-details"
              aria-selected="false" @click="selectedtab((e = 1))">
              Schemes
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-history-tab" data-bs-toggle="tab"
              data-bs-target="#voterhistorydetails" type="button" role="tab" aria-controls="customer-details"
              aria-selected="false" @click="selectedtab((e = 2))">
              History
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button class="nav-link" id="voter-hierarchy-tab" data-bs-toggle="tab" data-bs-target="#voterhierachydetails"
              type="button" role="tab" aria-controls="contact-history" aria-selected="false"
              @click="voterFamilyRelationList()">
              Family Hierarchy
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-engagements-tab" data-bs-toggle="tab"
              data-bs-target="#voterengagementsdetails" type="button" role="tab" aria-controls="contact-history"
              aria-selected="false" @click="selectedtab((e = 6))">
              Engagements
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button disabled class="nav-link" id="voter-influencers-tab" data-bs-toggle="tab"
              data-bs-target="#voterinfluencersdetails" type="button" role="tab" aria-controls="contact-history"
              aria-selected="false" @click="selectedtab((e = 7))">
              Influencers
            </button>
          </li> -->
        </ul>
        <div class="modal-body modal-body-scroll-outer">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="votercarddetails" role="tabpanel"
              aria-labelledby="voter-idcard-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloadervotermodal">
                  <div v-if="voterinputdisplayflag">
                    <div class="row" v-if="voterarraylength < 3">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-3 col-md-3 col-12">
                            <div class="custom-form-group">
                              <label for="formVoterInput" class="form-label">Voter Id
                                <span class="text-danger">*</span></label>
                              <input type="text" v-model="votercardnumber" class="form-control text-uppercase"
                                id="formVoterInput" @input="checkVoterId()" placeholder="Enter Voter Id"
                                autocomplete="off" maxlength="10" />
                              <span class="custom-error" v-if="voteriderr">
                                {{ voteriderr }}
                              </span>
                            </div>
                          </div>
                          <div class="
                              col-lg-4 col-md-4 col-12
                            ">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary"
                                @click="fetchvoteriddetails()" :disabled="errStatus || votercardnumber.length == 0 || showloader
                                  " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row mb-3" v-if="alreadyExitVoterMessage">
                          <div class="col-12">
                            <div class="custom-img-error">{{ this.alreadyExitVoterMessage }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Divider class="mt-0 mb-3" v-if="voterdivdisplayflag && voterarraylength < 3" />
                  <!-- open modal to display if voter ID was exits are Single Only start here -->
                  <div v-if="voterdivdisplayflag && voterarraylength == 1">
                    <div class="voter-detail-card mb-3" v-for="votergovdata in voterTabDetails" :key="votergovdata">
                      <div class="row mb-2">
                        <div class="text-end">
                          <span v-if="votergovdata.ab26 == 1" class="status-primary">Primary</span>
                          <span v-else-if="votergovdata.ab26 == 2" class="status-secondary">Secondary</span>
                          <span v-else class="status-other">Other</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Voter Number</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Age</label>
                            <div class="from-label-value">
                              {{
                                votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Gender</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak22
                                ? votergovdata.ak22 == 1
                                  ? "Male"
                                  : votergovdata.ak22 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Number</label>
                            <div class="from-label-value">
                              {{
                                votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Polling Station</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Area</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak44
                                ? votergovdata.ak44
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">State</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ak48
                                ? votergovdata.ak48
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab22
                                ? votergovdata.ab22
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency Number</label>
                            <div class="from-label-value">
                              {{
                                votergovdata.ab23
                                ? votergovdata.ab23
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Parliamentary Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovdata.ab24
                                ? votergovdata.ab24
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- open modal to display if voter ID was exits are Single Only end here -->
                  <!-- open modal to display if voter ID was exits are Multiple start here -->
                  <div class="d-flex align-items-start" v-if="voterarraylength > 1">
                    <ul class="nav nav-tabs custom-vertical-modal-tab flex-column me-3" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation" v-for="(votergovdata, index) in voterTabDetails"
                        :key="index">
                        <button class="nav-link" :class="votergovdata.ab26 == 1 ? 'active' : ''"
                          :id="'headingNumberSet' + index" data-bs-toggle="tab"
                          :data-bs-target="'#collapseVirtualNumber' + index" type="button" role="tab"
                          aria-selected="true">
                          <span class="d-flex justify-content-between align-items-center"><span>{{ votergovdata.ak35
                          }}</span>
                            <span class="user-badge status-active" v-if="votergovdata.ab26 == 1"><i
                                class="pi pi-check-circle text-success me-1"
                                :style="{ fontSize: '10px' }"></i>Primary</span></span>
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div class="tab-pane fade" :class="votergovdata.ab26 == 1 ? 'show active' : ''"
                        :aria-labelledby="'headingNumberSet' + index" role="tabpanel"
                        :id="'collapseVirtualNumber' + index" v-for="(votergovdata, index) in voterTabDetails"
                        :key="index">
                        <div class="row" v-if="votergovdata.ab26 != 1">
                          <div class="col-lg-12 col-12 pe-4 text-end">
                            <label class="from-label-value me-3">Set as Primary Voter Id</label>
                            <InputSwitch @change="confirmModalOpen(votergovdata)" />
                          </div>
                        </div>
                        <Divider v-if="votergovdata.ab26 != 1" />
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Voter Number</label>
                              <div class="from-label-value text-uppercase">
                                {{
                                  votergovdata.ak35 ? votergovdata.ak35 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak5 ? votergovdata.ak5 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Age</label>
                              <div class="from-label-value">
                                {{
                                  votergovdata.ak19 ? votergovdata.ak19 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Gender</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak22
                                  ? votergovdata.ak22 == 1
                                    ? "Male"
                                    : votergovdata.ak22 == 2
                                      ? "Female"
                                      : "Others"
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab4 ? votergovdata.ab4 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Part Number</label>
                              <div class="from-label-value">
                                {{
                                  votergovdata.ak85 ? votergovdata.ak85 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Polling Station</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab7 ? votergovdata.ab7 : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Area</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak44
                                  ? votergovdata.ak44
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">State</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ak48
                                  ? votergovdata.ak48
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab22
                                  ? votergovdata.ab22
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Assembly Constituency Number</label>
                              <div class="from-label-value">
                                {{
                                  votergovdata.ab23
                                  ? votergovdata.ab23
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6 col-md-6 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Parliamentary Constituency</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  votergovdata.ab24
                                  ? votergovdata.ab24
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- open modal to display if voter ID was exits are Multiple end here -->
                  <!-- when enter voter id display confirmation detail to update start here -->
                  <div v-if="voterupdatedisplayflag">
                    <div class="voter-detail-card mb-3">
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Voter Number</label>
                            <div class="from-label-value text-uppercase">
                              {{
                                votergovconfirmdata.ak35 ? votergovconfirmdata.ak35 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak5 ? votergovconfirmdata.ak5 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Age</label>
                            <div class="from-label-value">
                              {{
                                votergovconfirmdata.ak19 ? votergovconfirmdata.ak19 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-12 text-end" v-if="updateVoterBtnStatus">
                          <button type="button" @click="confirmUpdateModalOpen(votergovconfirmdata)"
                            class="btn custom-view-detail-btn" :disabled="showupdateloader" :style="{ width: '70px' }">
                            <span v-if="!showupdateloader">Confirm</span>
                            <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                              v-if="showupdateloader">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Gender</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak22
                                ? votergovconfirmdata.ak22 == 1
                                  ? "Male"
                                  : votergovconfirmdata.ak22 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Name</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab4 ? votergovconfirmdata.ab4 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Part Number</label>
                            <div class="from-label-value">
                              {{
                                votergovconfirmdata.ak85 ? votergovconfirmdata.ak85 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Polling Station</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab7 ? votergovconfirmdata.ab7 : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Area</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak44
                                ? votergovconfirmdata.ak44
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">State</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ak48
                                ? votergovconfirmdata.ak48
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab22
                                ? votergovconfirmdata.ab22
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Assembly Constituency Number</label>
                            <div class="from-label-value">
                              {{
                                votergovconfirmdata.ab23
                                ? votergovconfirmdata.ab23
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-4 col-md-4 col-12">
                          <div class="customer-label-group mb-2">
                            <label class="form-label">Parliamentary Constituency</label>
                            <div class="from-label-value text-capitalize">
                              {{
                                votergovconfirmdata.ab24
                                ? votergovconfirmdata.ab24
                                : "N/A"
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- when enter voter id display confirmation detail to update end here -->
                </div>
                <div v-if="showloadervotermodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voteraadhardetails" role="tabpanel" aria-labelledby="voter-aadharcard-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloaderaadharmodal">
                  <div v-if="aadhardivdisplayflag == 1">
                    <div class="row">
                      <div class="
                          col-lg-2 col-md-2 col-12
                          d-flex
                          align-items-center
                        ">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img v-if="AadharTabDetails.ak33 == null ||
                                AadharTabDetails.ak33 == ''
                                " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                width="90" />
                              <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/aadharphoto/' + AadharTabDetails.ak33" class="rounded bg-light me-auto d-block" alt="" width="90"
                                v-if="AadharTabDetails.ak33 != null &&
                                  AadharTabDetails.ak33 != ''
                                  " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  AadharTabDetails.ak5
                                  ? AadharTabDetails.ak5
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Aadhar Number</label>
                              <div class="from-label-value">
                              {{ AadharTabDetails.ak36 ? maskedNumber(AadharTabDetails.ak36) : "N/A" }}
                            </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharupdatebtnflag">
                        <button type="button" @click="confirmAadharUpdateModalOpen(AadharTabDetails.ak36)"
                          class="btn custom-view-detail-btn" :disabled="showupdateloader" :style="{ width: '70px' }">
                          <span v-if="!showupdateloader">Update</span>
                          <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                            v-if="showupdateloader">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </button>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12 text-end" v-if="aadharUpdateBtnDaysMsg">
                        <div class="col-12">
                          <div class="custom-img-error">{{ this.aadharUpdateBtnDaysMsg }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth</label>
                          <div class="from-label-value">
                            {{ format_date(AadharTabDetails.ak18) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value">
                            {{
                              AadharTabDetails.ak22
                              ? AadharTabDetails.ak22 == 1
                                ? "Male"
                                : AadharTabDetails.ak22 == 2
                                  ? "Female"
                                  : "Others"
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">House</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak148
                              ? AadharTabDetails.ak148
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Landmark</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak50
                              ? AadharTabDetails.ak50
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Street</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak147
                              ? AadharTabDetails.ak147
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Location</label>
                          <div class="from-label-value text-capitalize">
                            N/A
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Post Office</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak92
                              ? AadharTabDetails.ak92
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Area</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak44
                              ? AadharTabDetails.ak44
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">City</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak46
                              ? AadharTabDetails.ak46
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Sub Dist</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak146
                              ? AadharTabDetails.ak146
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">State</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak48
                              ? AadharTabDetails.ak48
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Address</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              AadharTabDetails.ak42
                              ? AadharTabDetails.ak42
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Pincode</label>
                          <div class="from-label-value">
                            {{
                              AadharTabDetails.ak97
                              ? AadharTabDetails.ak97
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Last Updated Date</label>
                          <div class="from-label-value">
                            {{ format_date(AadharTabDetails.ak137)}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="aadhardivdisplayflag == 0">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formaadharcardinput" class="form-label">Aadhar No.
                                <span class="text-danger">*</span></label>
                              <input type="text" @input="checkAadharNumber()" v-model="aadharcardnumber" class="form-control text-uppercase"
                                id="formaadharcardinput" placeholder="Enter Aadhar No." autocomplete="off" maxlength="12"
                                @keypress="onlyNumber" @paste="onPasteMobile" :disabled="displayMobilePopUp" />
                              <span class="custom-error" v-if="aadharerr">
                                {{ aadharerr }}
                              </span>
                              <span class="custom-error" v-if="sendOTPMessage">{{ sendOTPMessage }}</span>
                            </div>
                          </div>
                          <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="!displayMobilePopUp">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary" :style="{ width: '100px' }"
                                @click="sendAadharOtp()" :disabled="aadharcardnumber.length != 12 || showloader">
                                <span v-if="!showloader">Send OTP</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12" v-if="displayMobilePopUp">
                            <div class="custom-form-group">
                              <label for="formaadharcardinput" class="form-label">Enter OTP <span
                                  class="text-danger">*</span></label>
                              <input type="text" v-model="aadharotp" class="form-control otp-input-space"
                                id="formaadharcardinput" placeholder="Enter OTP" autocomplete="off" maxlength="6"
                                minlength="6" @keypress="onlyNumber" @paste="onPasteMobile" :disabled="mobile_otp_status == 1" />
                              <div class="d-flex align-items-center justify-content-between">
                                <span class="custom-error" v-if="mobileotperr">
                                  {{ mobileotperr }}
                                </span>
                                <span v-if="timerMobileStatus" class="otp-counter-text mt-1">{{
                                  timerMobileInterval
                                }}</span>
                                <button @click="resendAadharOtp()" class="btn btn-link aadhar-resent-otp-btn"
                                  v-if="resentotpbtnstatus" :style="{ width: '80px' }" :disabled="showresentloader">
                                  <span v-if="!showresentloader">Resend OTP</span>
                                  <div class="spinner-border custom-spinner-icon-loader-btn" role="status"
                                    v-if="showresentloader">
                                    <span class="visually-hidden">Loading...</span>
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          <div class="
                              col-lg-2 col-md-2 col-12
                            " v-if="displayMobilePopUp">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-success" @click="verifyAadharOtp()"
                                :style="{ width: '80px' }" :disabled="aadharotp.length != 6 || showloader">
                                <span v-if="!showloader">Verify</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="row" v-if="alreadyExitMessage">
                          <div class="col-12">
                            <div class="custom-img-error">{{ this.alreadyExitMessage }}</div>
                          </div>
                        </div>
                        <div class="row" v-if="systemlimiterrormsg">
                          <div class="col-lg-12 col-md-12 col-12">
                            <span class="custom-img-error">{{ systemlimiterrormsg }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showloaderaadharmodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterpandetails" role="tabpanel" aria-labelledby="voter-pancard-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloaderpanmodal">
                  <div v-if="pandivdisplayflag == 1">
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Pan Number</label>
                          <div class="from-label-value text-uppercase">
                            {{ PanTabDetails.ad3 ? PanTabDetails.ad3 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Name</label>
                          <div class="from-label-value text-capitalize">
                            {{ PanTabDetails.ad4 ? PanTabDetails.ad4 : "N/A" }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Category</label>
                          <div class="from-label-value">
                            {{
                              PanTabDetails.ad5 == 1
                              ? "Person"
                              : PanTabDetails.ad5 == 2
                                ? "Business"
                                : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="pandivdisplayflag == 0">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formpancardinput" class="form-label">Pan No
                                <span class="text-danger">*</span></label>
                              <input type="text" v-model="pancardnumber" class="form-control text-uppercase"
                                id="formpancardinput" placeholder="Enter Pan Card No" autocomplete="off" maxlength="10" />
                              <span class="custom-error" v-if="errpan">
                                {{ errpan }}
                              </span>
                              <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                              </span>
                            </div>
                          </div>
                          <div class="
                              col-lg-4 col-md-4 col-12
                            ">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary" @click="getpancarddetails()"
                                :disabled="errStatus || pancardnumber.length == 0 || showloader
                                  " :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                    spinner-border
                                    text-light
                                    custom-spinner-loader-btn
                                  " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showloaderpanmodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterdrivingdetails" role="tabpanel" aria-labelledby="voter-driving-tab">
              <div class="modal-scroll-min-height">
                <div v-if="!showloaderdrivingmodal">
                  <div v-if="drivingdivdisplayflag == 1">
                    <div class="row">
                      <div class="col-lg-2 col-md-2 col-12 d-flex align-items-center">
                        <div class="customer-label-group">
                          <div class="d-flex align-items-center">
                            <div>
                              <img v-if="LicenseTabDetails.ac18 == null ||
                                LicenseTabDetails.ac18 == ''
                                " src="/assets/images/profile.svg" class="rounded bg-light me-auto d-block" alt=""
                                width="90" />
                              <img :src="'https://storage.googleapis.com/' + this.voterstorageimgpath + '/drivingphoto/' + LicenseTabDetails.ac18" class="rounded bg-light me-auto d-block" alt=""
                                width="90" v-if="LicenseTabDetails.ac18 != null ||
                                  LicenseTabDetails.ac18 != ''
                                  " />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">License Number</label>
                              <div class="from-label-value text-uppercase">
                                {{
                                  LicenseTabDetails.ac3
                                  ? LicenseTabDetails.ac3
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Name</label>
                              <div class="from-label-value text-capitalize">
                                {{
                                  LicenseTabDetails.ac4
                                  ? LicenseTabDetails.ac4
                                  : "N/A"
                                }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-6 col-md-6 col-12">
                        <div class="row">
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Date of Issue</label>
                              <div class="from-label-value">
                                {{ format_date(LicenseTabDetails.ac14) }}
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-12 col-md-12 col-12">
                            <div class="customer-label-group mb-2">
                              <label class="form-label">Date of Expiry</label>
                              <div class="from-label-value">
                                {{ format_date(LicenseTabDetails.ac13) }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Date of Birth</label>
                          <div class="from-label-value">
                            {{ format_date(LicenseTabDetails.ac12) }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Gender</label>
                          <div class="from-label-value">
                            {{
                                LicenseTabDetails.ac11
                                ? LicenseTabDetails.ac11 == 1
                                  ? "Male"
                                  : LicenseTabDetails.ac11 == 2
                                    ? "Female"
                                    : "Others"
                                : "N/A"
                              }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Father / Husband Name</label>
                          <div class="from-label-value text-capitalize">
                            {{
                              LicenseTabDetails.ac15
                              ? LicenseTabDetails.ac15
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Address</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac5 ? LicenseTabDetails.ac5 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Permanent Pincode</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac6 ? LicenseTabDetails.ac6 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-8 col-md-8 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Address</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac7 ? LicenseTabDetails.ac7 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Temporary Pincode</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac8 ? LicenseTabDetails.ac8 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Name</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac9 ? LicenseTabDetails.ac9 : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-4 col-md-4 col-12">
                        <div class="customer-label-group mb-2">
                          <label class="form-label">Ola Code</label>
                          <div class="from-label-value">
                            {{
                              LicenseTabDetails.ac10
                              ? LicenseTabDetails.ac10
                              : "N/A"
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="drivingdivdisplayflag == 0">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formdrivinginput" class="form-label">Driving Licence No
                                <span class="text-danger">*</span></label>
                              <input type="text" v-model="drivingnumber" class="form-control text-uppercase"
                                id="formdrivinginput" placeholder="Enter Driving Licence No" autocomplete="off"
                                maxlength="15" />
                              <span class="custom-error" v-if="errdriving">
                                {{ errdriving }}
                              </span>
                              <span class="custom-error" v-if="errmsg">
                                {{ errmsg }}
                              </span>
                            </div>
                          </div>
                          <div class="col-lg-4 col-md-4 col-12">
                            <div class="custom-form-group">
                              <label for="formBirthDateInput" class="form-label">Birth Date<span
                                  class="text-danger">*</span></label>
                              <Calendar v-model="licencebirthdate" :minDate="minDateBirth" :maxDate="maxDateBirth"
                                :showIcon="true" class="custom-ultima-calendar" placeholder="DD/MM/YYYY"
                                dateFormat="dd/mm/yy" :manualInput="false" :monthNavigator="true"
                                :yearRange="'1950:' + maxDateBirth.getFullYear()" appendTo="body" :yearNavigator="true">
                              </Calendar>
                            </div>
                          </div>
                          <div class="
                            col-lg-4 col-md-4 col-12
                          ">
                            <div class="custom-form-group" style="margin-top: 26px;">
                              <button type="button" class="header-btn-outer btn btn-primary" @click="getdrivingdetails()"
                                :disabled="errStatus || drivingnumber.length == 0 || this.licencebirthdate == null || showloader"
                                :style="{ width: '104px' }">
                                <span v-if="!showloader">Get Details</span>
                                <div class="
                                  spinner-border
                                  text-light
                                  custom-spinner-loader-btn
                                " role="status" v-if="showloader">
                                  <span class="visually-hidden">Loading...</span>
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="showloaderdrivingmodal">
                  <div class="custom-modal-spinner-loader">
                    <i class="pi pi-spin pi-spinner" style="font-size: 2rem"></i>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-pane fade" id="voterschemesdetails" role="tabpanel" aria-labelledby="voter-schemes-tab">
              <div v-if="selected_tab == 1">
                <VoterSchemeDetailModalTabs />
              </div>
            </div>
            <div class="tab-pane fade" id="voterhistorydetails" role="tabpanel" aria-labelledby="voter-history-tab">
              <div v-if="selected_tab == 2">
                <VoterHistoryDetailsModalaTabs />
              </div>
            </div>
            <div class="tab-pane fade" id="voterhierachydetails" role="tabpanel" aria-labelledby="voter-hierarchy-tab">
              <div>
                <OrganizationCharts />
              </div>
            </div>
            <div class="tab-pane fade" id="voterengagementsdetails" role="tabpanel"
              aria-labelledby="voter-engagements-tab">
              <div v-if="selected_tab == 6">
                <EngagementTotalVoterModal />
              </div>
            </div>
            <div class="tab-pane fade" id="voterinfluencersdetails" role="tabpanel"
              aria-labelledby="voter-influencers-tab">
              <div v-if="selected_tab == 7">
                <MaintenerTotalVoterModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- next action modal end here -->
  <!-- confirmation update Voter Card as a primary modal start here -->
  <div class="modal-mask" v-if="confirm_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showconfirmprivoterloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="changePrimaryVoterStatus()"
              :disabled="showconfirmprivoterloaderbtn">
              <span v-if="!showconfirmprivoterloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status"
                v-if="showconfirmprivoterloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation update Voter Card as a primary modal end here -->
    <!-- confirmation Update Aadhar Details modal start here -->
    <div class="modal-mask" v-if="confirm_aadhar_update_popup_status">
    <div class="modal-dialog modal-xs modal-dialog-centered custom-modal-outer confirm-dialog-modal">
      <div class="modal-content text-center">
        <div class="modal-body">
          <div class="row">
            <div class="col-12">
              <div class="confirm-dialog-header">Confirmation</div>
            </div>
            <div class="col-12">
              <div class="d-flex align-items-center">
                <div class="flex-shrink-0">
                  <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                </div>
                <div class="flex-grow-1 ms-3 confirm-dialog-body">
                  Are you sure you want to proceed?
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="col-12 text-end">
            <button type="button" :disabled="showaconfirmloaderbtn" class="btn confirm-modal-no-btn"
              @click="confirmAadharUpdateModalClose()">
              No
            </button>
            <button type="button" class="btn confirm-modal-yes-btn" @click="updateAadharReloadPermission()"
              :disabled="showaconfirmloaderbtn">
              <span v-if="!showaconfirmloaderbtn">Yes</span>

              <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showaconfirmloaderbtn">
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- confirmation Update Aadhar Details modal end here -->
</template>
<script>
import ApiService from "../../service/ApiService";
import { required, requiredIf, minLength, maxLength, helpers } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import moment from 'moment';
export default {
  data() {
    return {
      v$: useValidate(),
      clientUserList: null,
      totalRecords: 0,
      page_no: 0,
      userStatusList: [
        { value: 1, label: "Active" },
        { value: 2, label: "Temporary Suspended" },
        { value: 3, label: "Permanent Suspended" },
      ],
      userInfo: {},
      loading: false,
      clientuserfiltermodals: false,
      clientusermodals: false,
      userstatus: null,
      mobilenumber: "",
      userrole_list: [],
      searchuser: "",
      filterflag: false,
      filterButtonShow: false,
      dissableFilterButton: false,
      update: {
        title: '',
        firstname: '',
        middlename: '',
        lastname: '',
        userdesignation: "",
        teamleadname: '',
        superuser: 1,
        userstatusflag: '',
        roletype: "",
        userrole: "",
        selectedTreeValue: [],
        activeStatusList: [
          { label: "Active", value: 1 },
          { label: "Temporary Suspended", value: 2 },
          { label: "Permanent Suspended", value: 3 },
        ],
        useremail: '',
      },
      rolesList: [],

      expandedKeys: {},
      userid: '',
      nodes: [],
      edituserloader: false,
      usermob: '',
      localmobno: '',

      userdesignationList: [],

      teamleadList: [],
      searchfullname: '',
      searchnameloader: false,
      searchnamebtnactive: false,

      voterEditStepStatus: false,
      editpermissionloader: false,
      allow: {
        usermobpermission: '',
        useremailpermission: '',
        useraadharpermission: '',
        usermobapppermission: true,
        useraddvotermanually: '',
        usereditpermission: '',
        displayusermobilenumberpermission: '',
        displayuseremailidpermission: '',
        displaykaryakartamobilenumberpermission: '',
        displaykaryakartaemailidpermission: '',
        displaydeletevoterpermission: '',

        displayaddvoterfamilypermission: '',
        displayaddvoterprofessionalinfopermission: '',
        displaymodifyvoterprofessionalinfopermission: '',
        displaymanagemodifyfamilyrelationpermission: '',
        displayremovememberfromfamilygrouppermission: '',
        displaymakeoutgoingcallvoterpermission: '',

        displayvotertagaddpermission: '',
        displayaddtagresponsepermission: 0,
        displayvotertagremovalpermission: '',
        displayremovaltagresponsepermission: 0,
      },
      user_info: '',
      user_storage_fk: '',
      alloweditvoterfk: '',
      unlockbtnloader: false,
      ak24: '',
      ak82: '',
      ak101: '',
      confirm_popup_status: false,
      confirm_unlock_popup_status: false,
      showconfirmloaderbtn: false,
      selected_tab: 1,
      viewvoterdetailstatus: false,
      pancardnumber: "",
      aadharcardnumber: "",
      pincodevalue: '',
      agerange: '',
      votercardnumber: "",
      drivingnumber: "",
      licencebirthdate: null,
      errpan: "",
      aadharerr: "",
      pincodeerr: '',
      agerangeeerr: '',
      agerangefromeerr: '',
      agerangetoeerr: '',
      voteriderr: "",
      errdriving: "",
      errmsg: "",
      showloader: false,
      errStatus: false,
      selectedvoter: null,
      voterdivdisplayflag: false,
      drivingdivdisplayflag: 0,
      pandivdisplayflag: 0,
      aadhardivdisplayflag: 0,
      mobile_otp_status: false,
      addvotermobile: "",
      addvotershowloader: false,
      aadharotp: "",

      displayMobilePopUp: false,
      timerMobileInterval: null,
      timerMobileStatus: false,
      timerMobileCount: 120,
      mobileotperr: "",
      callstatus: 0,
      msg: [],
      showloadervotermodal: false,
      showloaderaadharmodal: false,
      showloaderpanmodal: false,
      showloaderdrivingmodal: false,
      clientdesignationList: [],
      editvoterdetailstatus: false,
      countrycode: { value: 101, label: "+91" },
      countryCodeList: [],
      titleList: [],
      reassignbtnloader: false,
      showloaderreassignmodal: false,
      hidesearchname: false,
      hidesearchmob: false,
      assignuserList: [],
      user_team_status_allow: 0,
      alreadyExitMessage: '',
      sendOTPMessage: '',
      alreadyExitVoterMessage: '',
      votergovconfirmdata: [],
      showupdateloader: false,
      confirm_update_popup_status: false,
      confirm_aadhar_update_popup_status: false,
      showresentloader: false,
      resentotpbtnstatus: false,
      updateVoterBtnStatus: true,
      voterNoFK: '',
      showaconfirmloaderbtn: false,
      aadhar_number_fk: '',
      aadharupdatebtnflag: true,
      usercreateerrormsg: '',
      systemlimiterrormsg: '',

      voterexitid: '',
      addharexitid: '',
      voterexitstatus: '',
      exitvotererrormsg: false,
      callTrigerStatusList: '',
      virtualNumberCount: '',
      virtualNumberList: '',
      taggroupList: [],
      // campaign start
      bulkcampaignmodalflag: false,
      bulkwhatsapploader: false,
      capwhats: {
        selectbulkvoter: '',
        whatsapptemplate: '',
        datevariable: null,
        campaignname: ''
      },
      whatsapptemplateList: [],
      todaysDate: new Date(),
      bulkcallloader: false,
      capcall: {
        bulktunefile: null,
        datevariable: null,
        campaignname: '',
        calltemplate: '',

      },
      errorbulktunefile: '',
      exitbulkmp3previewurl: null,
      getcampaigntemplateList: [],
      userList: [],
      voterupdatedisplayflag: false,
      voterarraylength: 0,
      voterinputdisplayflag: false,
      switchuncheck: false,
      dynamicTrueActiveValue: '',
      dynamicFalseInactiveValue: '',
      showconfirmprivoterloaderbtn: false,
      confirm_tune_id: '',
      voteridToSwitch: '',
      confirm_status_value: '',
      voteridstatusfk: '',
      familyrelationstatus: '',
      familyTabDetails: '',
      rowId: "",
      mobile_number: "",
      AadharTabDetails: '',
      voterTabDetails: "",
      LicenseTabDetails: "",
      PanTabDetails: "",
      voterfiltermodalsflag: false,
      ak6: "",
      profession: [],
      ak20: [],
      ak21: '',
      ak22: [],
      ak19: "",
      ak19from: '',
      ak19to: '',
      ak49: "",
      ak44: [],
      ak35: "",
      ak36: '',
      ak85: [],
      ak120: '',
      ak121: [],
      agerangefrom: '',
      agerangeto: '',
      voterprofessionList: [],
      genderList: [
        { label: "Male", value: 1 },
        { label: "Female", value: 2 },
        { label: "Others", value: 3 },
      ],
      ageList: [],
      messageList: [],


      bloodgroupList: [],
      loksabhaList: [],
      vidhansabhaList: [],
      maxDateBirth: moment().subtract(1, "years")._d,
      minDateBirth: moment().subtract(120, "years")._d,
      addvoterloader: false,
      errorprofileimage: '',
      submitted: false,
      errormsg: "",

      vidhanloaderflag: false,
      cityloaderflag: false,
      arealoaderflag: false,
      client_info: '',
      voterstorageimgpath: '',
      exitpreviewurl: null,

      allowshowmobilevoterfk: '',
      allowshowemailvoterfk: '',
      allowshowaadharvoterfk: '',
      voterEditStepFirstStatus: false,
      voterEditStepTwoStatus: false,
      voterEditStepThrirdStatus: false,
      edituser: {
        votertype: '',
        roletype: '',
        userrole: "",
        userdesignation: "",
        teamleadname: '',
        selectedTreeValue: [],
      },
      editvoterloader: false,
      reasign: {
        assigntype: 1,
        reassignpartno: [],
        reassigntouser: '',
      },
      reassignpartnoList: [],
      updatevoterid: "",
      aadharUpdateBtnDaysMsg: '',
      superteamlead: '',
      allowshowusermobile: '',
      usermobilenumber: '',
      deletevotermessage: '',
    };
  },

  mounted() {
    this.getuserlist({ ak24: this.ak24, ak101: this.ak101, ak82: this.ak82 });
    this.getClientRoles();
    this.getModules();
    this.getalltitle();
    this.getuserdesignations();
    this.user_info = JSON.parse(localStorage.user);
    this.user_storage_fk = this.user_info.user_id;
    this.alloweditvoterfk = this.user_info.ak109;
    this.allowshowusermobile = (this.user_info.ak123 != null) ? this.user_info.ak123 : 0;
    this.client_info = JSON.parse(localStorage.client_info);
    this.voterstorageimgpath = this.client_info.maa24;
    this.allowshowemailvoterfk = this.user_info.ak106;
  },

  ApiService: null,
  interval: null,

  created() {
    this.ApiService = new ApiService();
  },

  watch: {
    mobilenumber() {
      this.filterChanges();
    },
    userrole() {
      this.filterChanges();
    },
    userstatus() {
      this.filterChanges();
    },
    pancardnumber(pancardnumber) {
      this.errmsg = "";
      this.validatePanCardNumber(pancardnumber);
      if (pancardnumber.length == 0) {
        this.errpan = "";
        this.errStatus = false;
      }
    },
    aadharcardnumber(aadharcardnumber) {
      this.errmsg = "";
      this.validateAadharCardNumber(aadharcardnumber);
      if (aadharcardnumber.length == 0) {
        this.aadharerr = "";
        this.errStatus = false;
      }
    },
    votercardnumber(votercardnumber) {
      this.errmsg = "";
      this.validateVoterIdNumber(votercardnumber);
      if (votercardnumber.length == 0) {
        this.voteriderr = "";
        this.errStatus = false;
      }
    },
    drivingnumber(drivingnumber) {
      this.errmsg = "";
      this.validateDrivingNumber(drivingnumber);
      if (drivingnumber.length == 0) {
        this.errdriving = "";
        this.errStatus = false;
      }
    },
  },
  validations() {
    return {
      aadharotp: {
        required: helpers.withMessage("Enter OTP", required),
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      update: {
        title: { required: helpers.withMessage('Please select title', required) },
        firstname: {
          required: helpers.withMessage("Please enter first name", required),
        },
        lastname: {
          required: helpers.withMessage("Please enter last name", required),
        },
        useremail: {
          required: helpers.withMessage("Please enter email", required),
        },
        roletype: {
          required: helpers.withMessage("Please select role type", required),
        },
        userrole: {
          requiredIf: helpers.withMessage(
            "Please select role designation",
            requiredIf(
              this.update.roletype == 1
            )
          ),
        },
        userdesignation: {
          required: helpers.withMessage("Please select designation", required),
        },
        teamleadname: {
          requiredIf: helpers.withMessage(
            "Please select team lead",
            requiredIf(
              this.teamleadList != null && this.superteamlead == 0
            )
          ),
        },
        userstatusflag: {
          required: helpers.withMessage("Please select status", required),
        },
        selectedTreeValue: {
          requiredIf: helpers.withMessage(
            "Please select module",
            requiredIf(
              this.update.roletype == 2
            )
          ),
        },
      },

    };
  },
  methods: {
    
    getalltitle(e) {
      this.ApiService.getalltitle(e).then((data) => {
        if (data.status == 200) {
          this.titleList = data.data;
        }
      });
    },
    getuserdesignations(e) {
      this.ApiService.getuserdesignations(e).then((data) => {
        if (data.status == 200) {
          this.userdesignationList = data.data;
        }
      });
    },
    getactiveusers(e) {
      this.ApiService.getactiveusers(e).then((data) => {
        if (data.status == 200) {
          this.teamleadList = data.data;
          for (let i = 0; i < this.teamleadList.length; i++) {
            let designation = '';
            if (this.teamleadList[i]['ak84'] != null && this.teamleadList[i]['ak84'] != '') {
              designation = ' (' + this.teamleadList[i]['ak84'] + ')';
            }
            this.teamleadList[i]['label'] = this.teamleadList[i]['label'] + '' + designation;
          }
          // this.showloadervotermodal = false;
        } else {
          this.teamleadList = null;
          // this.showloadervotermodal = false;
        }
      });
    },
    getModules(ev) {
      this.ApiService.getModules(ev).then(data => {
        if (data.status == 200) {
          this.nodes = data.data;
        } else {
          this.nodes = null;
        }
      })
    },
    filterChanges() {
      if (
        this.mobilenumber != "" ||
        this.userrole != null ||
        this.userstatus != null
      ) {
        this.filterButtonShow = true;
      } else {
        this.filterButtonShow = false;
      }
    },

    getuserlist(event) {
      this.loading = true;
      this.ApiService.getuserlist(event).then((items) => {
        if (items.success == true) {
          this.clientUserList = items.data;
          this.totalRecords = items.count;
          this.loading = false;
          if (this.filterflag) {
            this.dissableFilterButton = false;
          }
          this.searchnameloader = false;
          this.hidesearchname = false;
        } else {
          this.loading = false;
          this.clientUserList = null;
          this.totalRecords = 0;
          if (this.filterflag) {
            this.dissableFilterButton = false;
          } else {
            this.dissableFilterButton = true;
          }
          this.searchnameloader = false;
          this.hidesearchname = false;
        }
      });
    },

    changePage(event) {
      this.page_no = event.page;
      this.getuserlist({
        ak24: this.ak24,
        ak101: this.ak101,
        ak82: this.ak82,
        page_no: this.page_no,
      });
    },

    editClientUserInfoModalOpen(data) {
      this.getactiveusers({ ak1: data.ak1 });
      // this.teamleadList = this.teamleadList.filter(item => item.value !== data.ak1);
      // if(this.teamleadList){
      //   this.teamleadList = null;
      //   this.teamleadnonestatus = true;
      // }
      this.selectedTreeValue = '';
      this.voterEditStepStatus = false;
      this.clientusermodals = true;
      this.update.roletype = '';
      this.update.firstname = '';
      this.update.middlename = '';
      this.update.lastname = '';
      this.update.useremail = '';
      this.update.userstatusflag = '';
      this.userid = data.ak1;
      this.update.userrole = data.ak39;
      this.deletevotermessage = '';
      if(this.allowshowusermobile == 1) {
        this.usermobilenumber = data.ak24;
      } else if(this.allowshowusermobile == 0) {
        this.usermobilenumber = this.maskedNumber(data.ak24);
      }
      this.usermob = data.ak24;
      if (data.ak11 != null && data.ak11 != "") {
        this.update.title = {
          label: data.ak11,
          value: data.ak10,
        };
      }
      if(this.allowshowemailvoterfk == 1) {
        this.update.useremail = data.ak23;
      } else if(this.allowshowemailvoterfk == 0) {
        if(data.ak23 != null && data.ak23 != "") {
          this.update.useremail = this.maskedEmail(data.ak23);
        } else {
          this.update.useremail = '';
        }
      }
      this.superteamlead = data.ak143;
      this.update.firstname = data.ak7;
      this.update.middlename = data.ak8;
      this.update.lastname = data.ak9;
      if (data.ak110 != null && data.ak110 != '') {
        this.update.userdesignation = {
          label: data.ak111,
          value: data.ak110,
        };
      }
      if (data.ak39 != null && data.ak39 == '') {
        this.update.roletype = 2;
      } else {
        this.update.roletype = 1;
      }
      if (data.ak101 != null && data.ak101 != '') {
        this.update.teamleadname = {
          label: data.ak103,
          value: data.ak101,
        };
      }
      this.update.superuser = data.ak143;
      if (data.ak82 == 1) {
        this.update.userstatusflag = {
          label: "Active",
          value: data.ak82,
        }
      } else if (data.ak82 == 2) {
        this.update.userstatusflag = {
          label: "Temporary Suspended",
          value: data.ak82,
        }
      } else if (data.ak82 == 3) {
        this.update.userstatusflag = {
          label: "Permanent Suspended",
          value: data.ak82,
        }
      }
      if (data.ak41 != null) {
        let arrConverted = data.ak41.split(",");
        this.update.selectedTreeValue = arrConverted;
        for (let index = 0; index < arrConverted.length; index++) {
          let idxx = arrConverted[index];
          this.update.selectedTreeValue = {
            ...this.update.selectedTreeValue,
            [idxx]: { checked: true, partialChecked: false },
          };
        }
      }
      this.userInfo.btnstatus = 2;
      if (data.ak105 == 1) {
        this.allow.usermobpermission = true;
      } else {
        this.allow.usermobpermission = false;
      }

      if (data.ak106 == 1) {
        this.allow.useremailpermission = true;
      } else {
        this.allow.useremailpermission = false;
      }

      if (data.ak107 == 1) {
        this.allow.useraadharpermission = true;
      } else {
        this.allow.useraadharpermission = false;
      }

      if (data.ak108 == 1) {
        this.allow.usermobapppermission = true;
      } else {
        this.allow.usermobapppermission = true;
      }
      if(data.ak207 == 1) {
        this.allow.useraddvotermanually = true;
      } else {
        this.allow.useraddvotermanually = false;
      }
      if (data.ak109 == 1) {
        this.allow.usereditpermission = true;
      } else {
        this.allow.usereditpermission = false;
      }

      if (data.ak123 == 1) {
        this.allow.displayusermobilenumberpermission = true;
      } else {
        this.allow.displayusermobilenumberpermission = false;
      }

      if (data.ak124 == 1) {
        this.allow.displayuseremailidpermission = true;
      } else {
        this.allow.displayuseremailidpermission = false;
      }
      if (data.ak125 == 1) {
        this.allow.displaykaryakartamobilenumberpermission = true;
      } else {
        this.allow.displaykaryakartamobilenumberpermission = false;
      }
      if (data.ak126 == 1) {
        this.allow.displaykaryakartaemailidpermission = true;
      } else {
        this.allow.displaykaryakartaemailidpermission = false;
      }
      if (data.ak165 == 1) {
        this.allow.displaydeletevoterpermission = true;
      } else {
        this.allow.displaydeletevoterpermission = false;
      }
      if (data.ak196 == 1) {
        this.allow.displayaddvoterfamilypermission = true;
      } else {
        this.allow.displayaddvoterfamilypermission = false;
      }
      if (data.ak199 == 1) {
        this.allow.displayaddvoterprofessionalinfopermission = true;
      } else {
        this.allow.displayaddvoterprofessionalinfopermission = false;
      }
      if (data.ak200 == 1) {
        this.allow.displaymodifyvoterprofessionalinfopermission = true;
      } else {
        this.allow.displaymodifyvoterprofessionalinfopermission = false;
      }
      if (data.ak201 == 1) {
        this.allow.displaymanagemodifyfamilyrelationpermission = true;
      } else {
        this.allow.displaymanagemodifyfamilyrelationpermission = false;
      }
      if (data.ak202 == 1) {
        this.allow.displayremovememberfromfamilygrouppermission = true;
      } else {
        this.allow.displayremovememberfromfamilygrouppermission = false;
      }
      if (data.ak197 == 1) {
      this.allow.displayvotertagaddpermission = true;
      } else {
          this.allow.displayvotertagaddpermission = false;
      }
      if(data.ak204) {
          this.allow.displayaddtagresponsepermission = data.ak204;
      }
      if (data.ak198 == 1) {
          this.allow.displayvotertagremovalpermission = true;
      } else {
          this.allow.displayvotertagremovalpermission = false;
      }
      if(data.ak205) {
          this.allow.displayremovaltagresponsepermission = data.ak205;
      }
      if (data.ak203 == 1) {
        this.allow.displaymakeoutgoingcallvoterpermission = true;
      } else {
        this.allow.displaymakeoutgoingcallvoterpermission = false;
      }
    },
    updateClientUserDetail() {
      this.v$.update.$validate();
      let fields = {};
      fields["ak1"] = this.userid;
      if (this.update.userdesignation.value) {
        fields["ak110"] = this.update.userdesignation.value;
      }
      if (this.update.userdesignation.label) {
        fields["ak111"] = this.update.userdesignation.label;
      }
      if(this.update.superuser != 1) {
      if (this.update.teamleadname) {
          fields["ak101"] = this.update.teamleadname.value;
          let assignvalue = this.update.teamleadname.label.split(" (");
          fields["ak103"] = assignvalue[0];
        }
      }
      if(this.update.superuser) {
        fields["ak143"] = this.update.superuser;
      }
      // if (this.update.teamleadname) {
      //   let assignvalue = this.update.teamleadname.label.split(" (");
      //   fields["ak103"] = assignvalue[0];
      // }
      fields["ak82"] = this.update.userstatusflag.value;
      fields["role_type"] = this.update.roletype;
      fields["ak39"] = this.update.userrole;
      fields["modules"] = this.update.selectedTreeValue;
      fields["ak24"] = this.usermob;
      if (this.update.title.value) {
        fields["ak10"] = this.update.title.value;
      }
      if (this.update.title.label) {
        fields["ak11"] = this.update.title.label;
      }
      fields["ak7"] = this.update.firstname;
      if (this.update.middlename) {
        fields["ak8"] = this.update.middlename;
      }
      fields["ak9"] = this.update.lastname;
      fields["ak23"] = this.update.useremail;
      if (!this.v$.update.$error) {
        this.edituserloader = true;
        this.ApiService.updateclientuserpermissions(fields).then((items) => {
          if (items.status == 200) {
            this.$toast.open({
              message: items.message,
              type: "success",
              duration: 3000,
              position: "top-right",
            });
            setTimeout(() => {
              this.v$.$reset();
            }, 0);
            this.edituserloader = false;
            this.localmobno = localStorage.user.ak24;
            this.voterEditStepStatus = true;
          } else {
            this.$toast.open({
              message: items.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.edituserloader = false;
          }
        });
      }
    },
    updateClientUserPermission() {
      let fields = {};
      fields["ak1"] = this.userid;
      if (this.allow.usermobpermission == true) {
        fields["ak105"] = 1;
      } else if (this.allow.usermobpermission == false) {
        fields["ak105"] = 0;
      }

      if (this.allow.useremailpermission == true) {
        fields["ak106"] = 1;
      } else if (this.allow.useremailpermission == false) {
        fields["ak106"] = 0;
      }

      if (this.allow.useraadharpermission == true) {
        fields["ak107"] = 1;
      } else if (this.allow.useraadharpermission == false) {
        fields["ak107"] = 0;
      }

      if (this.allow.usermobapppermission == true) {
        fields["ak108"] = 1;
      } else if (this.allow.usermobapppermission == false) {
        fields["ak108"] = 0;
      }
      if(this.allow.useraddvotermanually == true) {
        fields["ak207"] = 1;
      } else if (this.allow.useraddvotermanually == false) {
        fields["ak207"] = 0;
      }
      if (this.allow.usereditpermission == true) {
        fields["ak109"] = 1;
      } else if (this.allow.usereditpermission == false) {
        fields["ak109"] = 0;
      }
      if (this.allow.displayusermobilenumberpermission == true) {
        fields["ak123"] = 1;
      } else if (this.allow.displayusermobilenumberpermission == false) {
        fields["ak123"] = 0;
      }
      if (this.allow.displayuseremailidpermission == true) {
        fields["ak124"] = 1;
      } else if (this.allow.displayuseremailidpermission == false) {
        fields["ak124"] = 0;
      }
      if (this.allow.displaykaryakartamobilenumberpermission == true) {
        fields["ak125"] = 1;
      } else if (this.allow.displaykaryakartamobilenumberpermission == false) {
        fields["ak125"] = 0;
      }
      if (this.allow.displaykaryakartaemailidpermission == true) {
        fields["ak126"] = 1;
      } else if (this.allow.displaykaryakartaemailidpermission == false) {
        fields["ak126"] = 0;
      }
      if (this.allow.displaydeletevoterpermission == true) {
        fields["ak165"] = 1;
      } else if (this.allow.displaydeletevoterpermission == false) {
        fields["ak165"] = 0;
      }
      if (this.allow.displayaddvoterfamilypermission == true) {
          fields["ak196"] = 1;
      } else if (this.allow.displayaddvoterfamilypermission == false) {
          fields["ak196"] = 0;
      }
      if (this.allow.displayaddvoterprofessionalinfopermission == true) {
          fields["ak199"] = 1;
      } else if (this.allow.displayaddvoterprofessionalinfopermission == false) {
          fields["ak199"] = 0;
      }
      if (this.allow.displaymodifyvoterprofessionalinfopermission == true) {
          fields["ak200"] = 1;
      } else if (this.allow.displaymodifyvoterprofessionalinfopermission == false) {
          fields["ak200"] = 0;
      }
      if (this.allow.displaymanagemodifyfamilyrelationpermission == true) {
          fields["ak201"] = 1;
      } else if (this.allow.displaymanagemodifyfamilyrelationpermission == false) {
          fields["ak201"] = 0;
      }
      if (this.allow.displayremovememberfromfamilygrouppermission == true) {
          fields["ak202"] = 1;
      } else if (this.allow.displayremovememberfromfamilygrouppermission == false) {
          fields["ak202"] = 0;
      }
      if (this.allow.displayvotertagaddpermission == true) {
        fields["ak197"] = 1;
          if(this.allow.displayaddtagresponsepermission) {
              fields["ak204"] = this.allow.displayaddtagresponsepermission;
          }
      } else if (this.allow.displayvotertagaddpermission == false) {
          fields["ak197"] = 0;
          fields["ak204"] = 0;
      }
      if (this.allow.displayvotertagremovalpermission == true) {
          fields["ak198"] = 1;
          if(this.allow.displayremovaltagresponsepermission) {
              fields["ak205"] = this.allow.displayremovaltagresponsepermission;
          }
      } else if (this.allow.displayvotertagremovalpermission == false) {
          fields["ak198"] = 0;
          fields["ak205"] = 0;
      }
      if (this.allow.displaymakeoutgoingcallvoterpermission == true) {
          fields["ak203"] = 1;
      } else if (this.allow.displaymakeoutgoingcallvoterpermission == false) {
          fields["ak203"] = 0;
      }
      this.editpermissionloader = true;
      this.ApiService.updateuserallowpermissions(fields).then((items) => {
        if (items.status == 200) {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
          this.voterEditStepStatus = false;
          this.clientusermodals = false;
          this.getuserlist();
        } else {
          this.editpermissionloader = false;
          this.$toast.open({
            message: items.message,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },

    closeClientUserModal() {
      this.clientusermodals = false;
      setTimeout(() => {
        this.v$.$reset();
      }, 0);
            this.expandedKeys = {};
    },
    reloadClientUserList() {
      this.getuserlist({ page_no: this.page_no });
    },

    userfilterModalOpen() {
      this.clientuserfiltermodals = true;
      this.getactiveusers();
    },
    userListfilterModalClose() {
      this.clientuserfiltermodals = false;

    },

    filterClientUser() {
      let fields = {};
      this.showloader = true;
      this.ApiService.getuserlist(fields).then((items) => {
        this.showloader = false;
        if (items.status == 200) {
          this.clientUserList = items.data;
          this.totalRecords = items.count;
        } else {
          this.clientUserList = null;
          this.totalRecords = 0;
        }
        this.$store.dispatch("clientUserFilterModalStatus", false);
        this.emitCustomEvent();
      });
    },

    getClientRoles(e) {
      this.ApiService.getclientroles(e).then((data) => {
        if (data.status == 200) {
          this.userrole_list = data.data;
        }
      });
    },
    filterListUser(ak24, ak101, ak82) {
      this.clientuserfiltermodals = false;
      this.ak24 = ak24;
      this.ak101 = ak101;
      this.ak82 = ak82;
      this.getuserlist({
        page_no: this.page_no,
        ak24: this.ak24,
        ak101: this.ak101,
        ak82: this.ak82,
      });
      this.filterflag = true;
    },

    resetUser() {
      this.ak24 = "";
      this.ak101 = '';
      this.ak82 = '';
      this.getuserlist();
      this.filterflag = false;
      this.clientuserfiltermodals = false;
    },

    // searchUserList(e) {
    //   this.getuserlist({
    //     user_name: e,
    //   });
    // },
    searchFnameUser(
      searchfullname
    ) {
      this.hidesearchname = true;
      this.searchnameloader = true;
      this.getuserlist({
        user_name: searchfullname,
      });
      this.searchnamebtnactive = true;
    },
    searchNameUserStop() {
      this.searchnamebtnactive = false;
      this.searchfullname = "";
      this.getuserlist();
      this.searchnameloader = false;
    },

    isLetterWithSpace(e) {
      let charspace = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z\s]+$/.test(charspace)) return true;
      else e.preventDefault();
    },
    isLetterWithOutSpace(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    onPasteMobile(event) {
      let mobvalue = event.clipboardData.getData('text');
      if (/^[0-9]+$/.test(mobvalue)) return true;
      else event.preventDefault();
    },
    onlyNumberMobile($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        $event.preventDefault();
      }

      var mobileInput = document.getElementById("formMobileNoInput");
      if ((mobileInput.value == '' || mobileInput.length > 1) && (keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52)) {
        $event.preventDefault();
      }
    },
    
    confirmUnlockModalOpen(e) {
      this.ak1 = e.ak1;
      this.confirm_unlock_popup_status = true;
    },

    confirmUnlockModalClose() {
      this.confirm_unlock_popup_status = false;
    },
    changelockstatus(e) {
      this.showconfirmloaderbtn = true;
      this.ApiService.changelockstatus({ ak1: e }).then((data) => {
        if (data.status == 200) {
          var successMsg = data.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.getuserlist();
          this.showconfirmloaderbtn = false;
          this.confirm_unlock_popup_status = false;
        } else {
          var errorMsg = data.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
          this.confirm_unlock_popup_status = true;
        }
      });
    },
    // Voter Details View Modal Common function start here
    voterdetailModalOpen(e) {
      this.rowId = '';
      this.mobile_number = '';
      this.voterNoFK = '';
      this.searchaadharmobile = '';
      this.licencebirthdate = null;
      this.rowId = e.ak1;
      this.familyrelationstatus = e.az6;
      this.mobile_number = e.ak24;
      this.voterNoFK = e.ak35;
      this.searchaadharmobile = e.ak24;
      if (e.ak18 != null && e.ak18 != "") {
        this.licencebirthdate = new Date(e.ak18);
      } else {
        this.licencebirthdate = null;
      }
      this.viewvoterdetailstatus = true;
      this.getvoterdetails();
      this.votercardnumber = "";
      this.aadharcardnumber = "";
      this.aadharotp = "";
      this.pancardnumber = "";
      this.drivingnumber = "";
      this.aadhar_number_fk = '';
      this.hideaadharmobile = false;
    },

    voterDetailModalClose() {
      this.viewvoterdetailstatus = false;
    },
    // Voter Details View Modal Common function end here
    // Get Driving Voter details function start here
    getlicensedetails() {
      this.errmsg = '';
      this.showloaderdrivingmodal = true;
      this.ApiService.getlicensedetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloaderdrivingmodal = false;
        } else {
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
          this.showloaderdrivingmodal = false;
        }
      });
    },
    getdrivingdetails() {
      this.errmsg = '';
      this.showloader = true;
      let fields = {};
      fields["ak1"] = this.rowId;
      fields["update"] = 0;
      fields["drivingnumber"] = this.drivingnumber;
      fields["dob"] = moment(this.licencebirthdate).format('YYYY-MM-DD');
      this.ApiService.getdrivinglicensedetails(fields).then((data) => {
        if (data.success == true) {
          this.LicenseTabDetails = data.data;
          this.drivingdivdisplayflag = 1;
          this.showloader = false;
        } else {
          this.errmsg = data.message;
          this.LicenseTabDetails = '';
          this.drivingdivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    // Get Driving Voter details function end here
    // Get Pan Card Voter details function start here
    getpandetails() {
      this.errmsg = '';
      this.showloaderpanmodal = true;
      this.ApiService.getpandetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.PanTabDetails = data.data;
          this.pandivdisplayflag = 1;
          this.showloaderpanmodal = false;
        } else {
          this.pandivdisplayflag = 0;
          this.showloaderpanmodal = false;
        }
      });
    },
    getpancarddetails() {
      this.errmsg = '';
      this.showloader = true;
      this.ApiService.getpancarddetails({ ak1: this.rowId, update: 0, pancardnumber: this.pancardnumber }).then((data) => {
        if (data.status == 200) {
          this.PanTabDetails = data.data;
          this.pandivdisplayflag = 1;
          this.showloader = false;
          this.errmsg = '';
        } else {
          this.errmsg = data.message;
          this.pandivdisplayflag = 0;
          this.showloader = false;
        }
      });
    },
    // Get Pan Card Voter details function end here
    // View voter details tab function start here 
    getvoterdetails() {
      this.voterTabDetails = '';
      this.voterarraylength = '';
      this.showloadervotermodal = true;
      this.ApiService.addvoterdetails({ ak1: this.rowId, update: 0, ak24: this.mobile_number }).then((data) => {
        if (data.success == true) {
          this.voterTabDetails = data.data;
          this.voterarraylength = data.data.length;
          this.voterdivdisplayflag = true;
          this.voterinputdisplayflag = true;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        } else {
          this.voterinputdisplayflag = true;
          this.voterdivdisplayflag = false;
          this.showloadervotermodal = false;
          this.alreadyExitVoterMessage = '';
        }
      });
    },
    confirmModalOpen(e) {
      this.confirm_tune_id = e.ak1;
      this.voteridToSwitch = e.ak35;
      this.voteridstatusfk = e.ab26
      this.confirm_popup_status = true;
      this.confirm_status_value = '';
    },
    confirmModalClose() {
      this.confirm_popup_status = false;
      this.confirm_tune_id = '';
      this.voteridToSwitch = '';
      this.confirm_status_value = '';
      this.getvoterdetails();
    },
    changePrimaryVoterStatus() {
      let fields = {};
      fields["ak1"] = this.confirm_tune_id;
      fields["ak35"] = this.voteridToSwitch;
      if (this.confirm_status_value == 0 || this.confirm_status_value == false) {
        fields["ab26"] = this.voteridstatusfk;
      }
      if (this.confirm_status_value == 1 || this.confirm_status_value == true) {
        fields["ab26"] = this.voteridstatusfk;
      }
      this.showconfirmprivoterloaderbtn = true;
      this.ApiService.voterCardSetAsPrimary(fields).then((items) => {
        if (items.status === 200) {
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmprivoterloaderbtn = false;
          this.getvoterdetails();
          this.confirm_tune_id = '';
          this.voteridToSwitch = '';
          this.confirm_status_value = '';
          this.voteridstatusfk = '';
          this.confirm_popup_status = false;
          setTimeout(() => {
            this.v$.$reset();
          }, 0);
        } else {
          this.showconfirmprivoterloaderbtn = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
        }
      });
    },
    confirmUpdateModalOpen(e) {
      this.updatevoterid = e.ak35;
      this.confirm_update_popup_status = true;
    },
    confirmUpdateModalClose() {
      this.confirm_update_popup_status = false;
    },
    fetchvoteriddetails() {
      this.showloader = true;
      let fields = {};
      var result = [];
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["voter_id"] = this.votercardnumber;
      fields["update"] = 0;
      this.ApiService.addvoterdetails(fields).then((items) => {
        if (items.success === true) {
          if (items.is_exists == 1) {
            result.push(items.data);
            this.alreadyExitVoterMessage = items.message;
            this.votercardnumber = '';
            this.showloader = false;
            this.voterinputdisplayflag = true;
            this.voterdivdisplayflag = true;
            this.voterTabDetails = result;
            var errorMsg = items.message;
            this.$toast.open({
              message: errorMsg,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
          } else {
            this.showloader = false;
            this.alreadyExitVoterMessage = items.message;
            this.voterTabDetails = items.data;
            this.voterinputdisplayflag = true;
            this.voterdivdisplayflag = false;
            this.voterupdatedisplayflag = true;
            this.votergovconfirmdata = items.data;
            var successMsg = items.message;
            this.$toast.open({
              message: successMsg,
              type: "warning",
              duration: 3000,
              position: "top-right",
            });
          }
        } else {
          var errorMsgs = items.message;
          this.$toast.open({
            message: errorMsgs,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showloader = false;
        }
      });
    },
    updateVoterPermission() {
      this.showconfirmloaderbtn = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["voter_id"] = this.updatevoterid;
      fields["update"] = 1;
      this.ApiService.addvoterdetails(fields).then((items) => {
        if (items.success === true) {
          this.updatevoterid = '';
          this.showloader = false;
          this.voterdivdisplayflag = false;
          this.confirm_update_popup_status = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
          // this.alreadyExitVoterMessage = '';
          // this.updateVoterBtnStatus = false;
          this.getvoterdetails();
          this.votergovconfirmdata = [];
          this.votercardnumber = '';
          this.showloader = false;
        } else {
          this.alreadyExitVoterMessage = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showconfirmloaderbtn = false;
        }
      });
    },
    // View voter details tab function end here
    // Get Aadhar Detail Form Function start here
    // First Get Aadhar Details API call
    getaadhardetails() {
      this.aadharUpdateBtnDaysMsg = '';
      this.showloaderaadharmodal = true;
      this.ApiService.getaadhardetails({ ak1: this.rowId }).then((data) => {
        if (data.success == true) {
          this.AadharTabDetails = data.data;
          if (this.AadharTabDetails.ak36 != '' && this.AadharTabDetails.ak36 != null) {
            this.aadhardivdisplayflag = 1;
            if (this.AadharTabDetails.update == 0) {
              this.aadharupdatebtnflag = false;
              if(this.AadharTabDetails.update_days != 30) {
                  this.aadharUpdateBtnDaysMsg = 'You can update Aadhar Card details after ' + (30 - this.AadharTabDetails.update_days) + ' Days';
              }
            } else {
              this.aadharupdatebtnflag = true;
              this.aadharUpdateBtnDaysMsg = '';
            }
          } else {
            this.aadhardivdisplayflag = 0;
          }
          this.showloaderaadharmodal = false;
        } else {
          this.showloaderaadharmodal = false;
        }
      });
    },
    confirmAadharUpdateModalOpen(aadhar) {
      this.confirm_aadhar_update_popup_status = true;
      this.aadhar_number_fk = aadhar;
    },
    confirmAadharUpdateModalClose() {
      this.confirm_aadhar_update_popup_status = false;
    },
    updateAadharReloadPermission() {
      this.showaconfirmloaderbtn = true;
      this.confirm_aadhar_update_popup_status = false;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["aadhar_number"] = this.aadhar_number_fk;
      fields["ak1"] = this.rowId;
      fields["update"] = 1;
      this.showloaderaadharmodal = true;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.aadharcardnumber = this.aadhar_number_fk;
          this.aadhardivdisplayflag = 0;
          this.showupdateloader = false;
          this.showaconfirmloaderbtn = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
          this.showloaderaadharmodal = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.sendOTPMessage = items.message;
          this.showaconfirmloaderbtn = false;
          this.showloaderaadharmodal = false;
          this.systemlimiterrormsg = items.message;
        }
      });
    },
    sendAadharOtp() {
      this.showloader = true;
      if (
        this.aadharcardnumber != "" &&
        this.aadharcardnumber != null &&
        this.aadharcardnumber.length == 12
      ) {
        let fields = {};
        fields["ak24"] = this.mobile_number;
        fields["aadhar_number"] = this.aadharcardnumber;
        fields["ak1"] = this.rowId;
        fields["update"] = 0;
        this.ApiService.generateaadharotp(fields).then((items) => {
          if (items.success === true) {
            // is_exits == 1 message display 
            // is_exits == 0 OTP Send
            if (items.data.is_exits == 1) {
              this.alreadyExitMessage = items.message;
              this.showloader = false;
              this.aadharcardnumber = '';
              this.displayMobilePopUp = false;
              var errorMsg = items.message;
              this.$toast.open({
                message: errorMsg,
                type: "error",
                duration: 3000,
                position: "top-right",
              });
            } else {
              this.alreadyExitMessage = '';
              this.displayMobilePopUp = true;
              this.startMobileTimer();
              this.timerMobileStatus = true;
              this.aadharotp = "";
              this.showloader = false;
              this.sendOTPMessage = items.message;
              var successMsg = items.message;
              this.$toast.open({
                message: successMsg,
                type: "success",
                duration: 3000,
                position: "top-right",
              });
            }
          } else {
            this.alreadyExitMessage = '';
            var errorMsgs = items.message;
            this.$toast.open({
              message: errorMsgs,
              type: "error",
              duration: 3000,
              position: "top-right",
            });
            this.showloader = false;
            this.aadharcardnumber = '';
            this.aadharotp = "";
            this.timerMobileStatus = false;
            this.systemlimiterrormsg = items.message;
          }
        });
      } else {
        this.$toast.open({
          message: "Please enter valid Aadhar No.",
          type: "warning",
          duration: 3000,
          position: "top-right",
        });
      }
    },
    resendAadharOtp() {
      this.showresentloader = true;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["aadhar_number"] = this.aadharcardnumber;
      fields["ak1"] = this.rowId;
      fields["update"] = 0;
      fields["resend_otp"] = 1;
      this.ApiService.generateaadharotp(fields).then((items) => {
        if (items.success === true) {
          this.showupdateloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.displayMobilePopUp = true;
          this.startMobileTimer();
          this.timerMobileStatus = true;
          this.aadharotp = "";
          this.mobileotperr = '';
          this.showloader = false;
          this.sendOTPMessage = items.message;
          this.mobile_otp_status = 0;
          this.showresentloader = false;
          this.resentotpbtnstatus = false;
        } else {
          this.resentotpbtnstatus = false;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.showresentloader = false;
          this.sendOTPMessage = items.message;
          this.systemlimiterrormsg = items.message;
        }
      });
    },
    verifyAadharOtp() {
      this.showloader = true;
      this.mobile_otp_status = 0;
      let fields = {};
      fields["ak24"] = this.mobile_number;
      fields["ak1"] = this.rowId;
      fields["otp"] = this.aadharotp;
      fields["aadhar_number"] = this.aadharcardnumber;
      fields["update"] = 1;
      this.ApiService.getaadharcarddetails(fields).then((items) => {
        if (items.success === true) {
          this.showloaderaadharmodal = false;
          this.aadhardivdisplayflag = 1;
          this.aadharcardnumber = '';
          this.aadharerr = '';
          this.sendOTPMessage = '';
          this.displayMobilePopUp = false;
          this.aadharotp = '';
          this.timerMobileStatus = false;
          this.timerMobileCount = 120;
          this.alreadyExitMessage = '';
          this.AadharTabDetails = items.data;
          this.showloader = false;
          var successMsg = items.message;
          this.$toast.open({
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          });
          this.aadharupdatebtnflag = false;
        }
        else if (items.status == 500) {
          this.mobile_otp_status = 1;
        } else {
          this.sendOTPMessage = '';
          this.sendOTPMessage = '';
          this.showloader = false;
          this.mobileotperr = items.message;
          var errorMsg = items.message;
          this.$toast.open({
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          });
          this.timerMobileStatus = false;
          this.timerMobileCount = 0;
          this.aadharotp = "";
          this.showloader = false;
          this.resentotpbtnstatus = true;
          setTimeout(() => {
            this.mobile_otp_status = 1;
          }, 480000);
          // 8 minutes set timeout
        }
      });
    },
    startMobileTimer() {
      this.timerMobileStatus = true;
      if (this.timerMobileCount > 0) {
        setTimeout(() => {
          this.timerMobileCount--;
          this.startMobileTimer();
        }, 1000);
        const minutes = Math.floor(this.timerMobileCount / 60);
        let seconds = this.timerMobileCount % 60;
        if (seconds < 10) {
          this.timerMobileInterval = `0${minutes}:0${seconds}`;
        } else {
          this.timerMobileInterval = `0${minutes}:${seconds}`;
        }
      } else {
        this.timerMobileCount = 120;
        this.timerMobileStatus = false;
        this.resentotpbtnstatus = true;
      }
    },
    // Get Aadhar Detail Form Function end here
    // family hierarchy view detail start here
    voterFamilyRelationList() {
      this.ApiService.voterFamilyRelationList({ ak1: this.familyrelationstatus }).then((data) => {
        if (data.success == true) {
          this.familyTabDetails = data.data;
        } else {
          this.familyTabDetails = '';
        }
      });
    },
    // family hierarchy view detail start here
    validatePanCardNumber(val) {
      this.errStatus = false;
      if (!this.validPAN(val)) {
        this.errpan = "Please enter valid pan number";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errpan = "";
        return true;
      }
    },

    validPAN: function (e) {
      var re = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
      return re.test(e);
    },
    validateAadharCardNumber(e) {
      this.errStatus = false;
      if (!this.validAadharCard(e)) {
        this.aadharerr = "Please enter valid Aadhar No.";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.aadharerr = "";
        return true;
      }
    },

    validAadharCard: function (e) {
      var re = /^((?!(0))[0-9]{12})$/;
      return re.test(e);
    },
    validateVoterIdNumber(e) {
      this.errStatus = false;
      if (!this.validVoterId(e)) {
        this.voteriderr = "Please enter valid voter id";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.voteriderr = "";
        return true;
      }
    },

    validVoterId: function (e) {
      var re = /([A-Za-z]){3}([0-9]){7}$/;
      return re.test(e);
    },

    validateDrivingNumber(e) {
      this.errStatus = false;
      if (!this.validDrivingNo(e)) {
        this.errdriving = "Please enter valid driving licence no";
        this.errStatus = true;
      }
      if (this.errStatus == false) {
        this.errdriving = "";
        return true;
      }
    },

    validDrivingNo: function (e) {
      var re = /([A-Za-z]){2}([0-9]){13}$/;
      return re.test(e);
    },
    checkVoterId() {
      var voterlength = this.votercardnumber.length;
      if (voterlength == 10) {
        var errorflag = 0;
        for (var i = 0, len = this.voterTabDetails.length; i < len; i++) {
          if (this.voterTabDetails[i]['ak35'] === this.votercardnumber.toUpperCase()) {
            errorflag = 1;
            break;
          }
        }
        if (errorflag == 1) {
          this.alreadyExitVoterMessage = 'This Voter Id is already added to your account!';
          this.errStatus = true;
          this.votercardnumber.length == 1;
        } else {
          this.alreadyExitVoterMessage = '';
          this.errStatus = false;
          this.votercardnumber.length == 0;
        }
      } else {
        this.alreadyExitVoterMessage = '';
        this.errStatus = true;
        this.votercardnumber.length == 1;
      }

    },
    checkAadharNumber(){
      if(this.alreadyExitMessage != ''){
        this.alreadyExitMessage = '';
      }else{
        return true;
      }
    },
    format_date(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY");
      } else {
        return 'N/A';
      }
    },
    maskedNumber(getvalue) {
      const maskedDigits = 'X'.repeat(getvalue.length - 4);
      const FirstThreeDigits = getvalue.slice(0, 2);
      const lastThreeDigits = getvalue.slice(-2);
      return FirstThreeDigits + maskedDigits + lastThreeDigits;
    },
    onlyNumber($event) {
            let keyCode = $event.keyCode ? $event.keyCode : $event.which;
            if (keyCode < 48 || keyCode > 57) {
                $event.preventDefault();
            }
        },
    selectedtab(e) {
      this.selected_tab = e;
    },
    expandAll() {
      for (let node in this.nodes) {
        this.expandNode(this.nodes[node]);
      }
      this.expandedKeys = { ...this.expandedKeys };
    },

    collapseAll() {
      this.expandedKeys = {};
    },

    expandNode(node) {
      if (node.children && node.children.length) {
        this.expandedKeys[node.key] = true;

        for (let child of node.children) {
          this.expandNode(child);
        }
      }
    },
    maskedEmail(getvalue) {
        const [name, domain] = getvalue.split('@');
        const [firstDomain, sublastdomain, lastdomain] = domain.split('.');
        if(name.length == 1 && !lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}`;
        } else if(name.length == 1 && lastdomain) {
            return `${name}@${new Array(firstDomain.length).join('*')}.${sublastdomain}.${lastdomain}`;
        } else {
            return `${name[0]}${new Array(name.length).join('*')}@${domain}`;
        }
    },
    CheckAllowDeleteVoterAccess(e) {
        if (e == true) {
          this.ApiService.checkAllowToDelete(this.allow.displaydeletevoterpermission).then((data) => {
            if (data.status == 200) {
              this.deletevotermessage = data.message;
              this.allow.displaydeletevoterpermission = 0;
              this.allow.displaydeletevoterpermission = false;
            } else {
              this.deletevotermessage = '';
              this.allow.displaydeletevoterpermission = 1;
              this.allow.displaydeletevoterpermission = true;
            }
          });
        } else if (e == false) {
            this.allow.displaydeletevoterpermission = 0;
            this.allow.displaydeletevoterpermission = false;
        }
    }
  },
};
</script>

<style scoped>
.modal-bulk-next-btn.btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 20px;
  padding: 6px 35px;
  font-family: "AcuminPro-Regular";
  width: 115px;
}

.modal-bulk-next-btn:focus {
  box-shadow: none;
}

.filter-apply-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  line-height: 13px;
  height: 34px;
}

.filter-reset-btn {
  letter-spacing: 0.26px;
  color: #5266e5;
  font-size: 13px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.filter-reset-btn:focus-visible,
.filter-apply-btn:focus-visible {
  outline: 0;
}

.filter-reset-btn:focus,
.filter-apply-btn:focus {
  box-shadow: none;
}

.status-active {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-temporary-suspended {
  background: #fde3c8;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #bba691;
  line-height: 12px;
}

.status-permanently-suspended {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: capitalize;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.customer-label-group .from-label-value {
  font-family: "AcuminPro-SemiBold";
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #2b2e38;
  line-height: 20px;
}

.customer-label-group {
  margin-bottom: 16px;
}

.customer-label-group .form-label {
  font-family: "AcuminPro-Regular";
  font-size: 13px;
  letter-spacing: 0px;
  color: #4a5463;
  line-height: 13px;
  margin-bottom: 6px;
}

.divider-custom-list {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #d4e4f3;
}

.locked-user-btn {
  background: #5266e5 0% 0% no-repeat padding-box;
  border-color: #5266e5;
  border-radius: 4px;
  letter-spacing: 0.26px;
  color: #ffffff;
  font-size: 13px;
  line-height: 16px;
  padding: 6px 12px;
  font-family: "AcuminPro-Regular";
  width: 97px;
}

.locked-user-btn:hover {
  box-shadow: none;
}

.custom-outline-lock-btn {
  background: #fde4e7 0% 0% no-repeat padding-box;
  border: 1px solid #dbb8b8;
  border-radius: 4px;
  padding: 7.5px;
  line-height: 10px;
}

.custom-outline-lock-btn .lock-btn-color {
  color: #c63737;
  font-size: 13px;
}

.status-primary {
  background: #c8e6c9;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #256029;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #4daa53;
  line-height: 12px;
}

.status-secondary {
  background: #ffd8b2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #805b36;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #c79b6f;
  line-height: 12px;
}

.status-other {
  background: #ffcdd2;
  border-radius: 2px;
  letter-spacing: 0.3px;
  color: #c63737;
  opacity: 1;
  font-size: 12px;
  font-family: "AcuminPro-Bold";
  padding: 0.3em 0.2rem 0.2em 0.2rem;
  text-transform: uppercase;
  border: 1px solid #d7a5a5;
  line-height: 12px;
}

.user-badge {
  border-radius: 2px;
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: 0.3px;
}
.aadhar-resent-otp-btn {
  letter-spacing: 0px;
  color: #3362db;
  font-size: 14px;
  font-family: "AcuminPro-Regular";
  padding: 0;
  text-decoration: none;
}

.aadhar-resent-otp-btn:focus-visible {
  outline: 0;
}

.aadhar-resent-otp-btn:focus {
  box-shadow: none;
}
</style>